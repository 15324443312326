.file {
    margin: auto;
    font-size: 1.6rem;
}

.file-label {
	padding: 0 1.1rem 0 1.1rem;
	height: 3.4rem;
	border-style: solid;
	border-radius: .6rem;
	border-color: var(--gray);
	cursor: pointer;
    background-color: var(--green);
    color: var(--white);
    border-width: 0;
    box-shadow: 0px 6px 6px -6px rgba(105, 158, 117, 0.5);
}

.file-input-field{
    display: flex;
    flex-direction: column;
    margin: 1rem;
    padding: 4px;
}