.field-required-mark {
    color:red;
}

.field-description {
    /* font-size: 11px; */
    color: #7398b6;
	height: 2rem;
}

.input-simple, .input-simple-sm {
	border: .1rem solid var(--gray);
	border-radius: .6rem;
	color: var(--blue-level-03);
	font-family: Saira,sans-serif;
	font-size: 1.2rem;
	font-weight: 500;
	padding-left: 1rem;
}

select:invalid,
textarea:invalid,
input:invalid {
	border-color: rgba(255,0,0,0.4);
	background-color: rgba(255,0,0,0.1);
}