

.multiple-select {
    height:auto !important;
}

.input-field-select{
    border: .1rem solid var(--gray);
    border-radius: .6rem;
    color: var(--blue-level-03);
    font-family: Saira,sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    padding-left: 1rem;
    height: 4.2rem;
    margin: 2rem 0;
    background: none;
    width:100%;
}