.input-field {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding: 4px;
  justify-content: space-between;
}

input{
	border: .1rem solid var(--gray);
	border-radius: .6rem;
	color: var(--blue-level-03);
	font-family: Saira,sans-serif;
	font-size: 1.2rem;
	font-weight: 500;
	padding-left: 1rem;
  height: 3.8rem;
  margin: 2rem 0;
}