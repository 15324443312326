
@import url('https://fonts.googleapis.com/css2?family=Saira:wght@300;400;500;700&display=swap');

:root {
    --gray: #D1D5DB;
    --blue-level-01: #F0F3F8;
    --blue-level-02: #819AB0;
    --blue-level-03: #476481;
    --blue-level-04: #102A43;
    --cyan: #B6E0FD;
    --green: #37BDC9;
    --red: #BA2424;
    --orange: #F0B428;
    --white: #fff;
    --stroke: #D9E2EC
}

html {
  box-sizing: border-box;
  font-size: 10px;
}

.nav-container {
  min-height: 6.4rem;
  background-color: var(--blue-level-04);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 4rem 0 4rem;
}

.text-01 {
  font-family: 'Saira', sans-serif;
  font-weight: 600;
  font-size: 2.5rem;
}

.text-02 {
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
}

.text-03 {
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.4rem;
}

.text-07 {
	font-family: Saira,sans-serif;
	font-size: 2rem;
	font-weight: 400;
}

.m-2 {
  margin: 0.2rem
}

.main-container {
  width:800px;
  margin: 25px auto;
  box-shadow: 0 4px 8px 0 rgba(53,105,128,.3),0 6px 20px 0 rgba(165,200,213,.41);
  background-color: var(--blue-level-01);
  font-family: Saira,sans-serif;
  color: var(--blue-level-03);
}

.form-logo {
  margin: 0 4rem 0 0;
  width:130px;
  height:40px
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--blue-level-01);
  border-width: 0.2rem;
}

.form-content {
  padding:10px;
  text-align: center;
  background: #fff;
}

.form-header {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(20px + 2vmin);
  font-weight: bold;
}

.client-info {
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "first-name second-name"
    "email-address email-address"
  ;
}

.first-name {
  grid-area: first-name;
}

.second-name {
  grid-area: second-name;
}

.email-address {
  grid-area: email-address;
}

.complaint-info-manual {
  display:grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "order-id reason"
    "positions positions"
    "add-row-btn add-row-btn"
    "complaints-detail complaints-detail"
    /* "description description"
    "file file"
    "expectations expectations" */
  ;
}

.complaints-detail {
  grid-area: complaints-detail;
}

/*man*/
.order-id {
  grid-area: order-id;
}

.positions {
  grid-area: positions
}

.position-row {
  display: flex;
  align-items: center;
  /* grid-area: position-row;
  grid-template-columns: 2fr 1fr 0.2fr;
  grid-template-rows: auto; */
}

/* .position-row div:first-child  {
  flex-grow:2;
}  */

.auto-position-select-fg2 {
  flex-grow:2;
}

/*auto*/
.complaint-info-auto {
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "order-id order-id reason"
    "positions positions positions"
    "complaints-detail complaints-detail complaints-detail"
    /* "description description description"
    "file file file"
    "expectations expectations expectations" */
  ;
}

.order-id-select {
  grid-area: order-id;
}

.reason {
  grid-area: reason;
}

.description {
  grid-area: description;
}
.description textarea {
  height:200px
}

.file {
  grid-area: file;
}

.input-file {
  /* display: none; */
  position: absolute;
  width: 1px;
  height: 1px;
  border: 0px;
}

.file-input-div {
  display:flex;
  width:100%;
  align-items:center
}

.file-input-div > a {
  width: 20rem;
}

.file-input-div > a > label {
  display:flex;
  justify-content: center;
  align-items: center;
}

.file-input-div > a > label > span {
  font-size: 1.2rem;
  font-weight: 500;
  padding-left: 1rem;
  margin: 2rem 0;
}

.file-input-div > div {
  flex-grow: 9
}

.manul-entry-input {
  height: 1.5rem !important;
  margin: 1rem 0;
}

.manual-entry-label {
  display:flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}

.expectations {
  grid-area: expectations;
}

.expectations textarea {
  height:200px
}

.btn {
  padding: 0 1.1rem 0 1.1rem;
  height: 3.4rem;
  font-family: 'Saira', sans-serif;
  font-weight: 500;
  font-size: 1.2rem;
  border-style: solid;
  border-width: 0.1rem;
  border-radius: .6rem;
  border-color: var(--gray);
  cursor: pointer;
  background-color: var(--white);
  color: var(--blue-level-03);
}

.btn:disabled {
  opacity: 0.65;
}

.btn-primary {
  background-color: var(--green);
  color: var(--white);
  border-width: 0;
  box-shadow: 0px 6px 6px -6px rgba(105, 158, 117, 0.5);
}

.btn-success {
	color: rgb(255, 255, 255);
	background-color: rgb(40, 167, 69);
	border-color: rgb(40, 167, 69);
}

.btn-warning {
  background-color: var(--red);
  color: var(--white);
  border-width: 0;
  box-shadow: 0px 6px 6px -6px rgba(224, 44, 20, 0.5);
}

.btn-secondary {
  background-color: var(--orange);
  color: var(--white);
  border-width: 0;
  box-shadow: 0px 6px 6px -6px rgba(224, 160, 20, 0.5);
}

.btn-lang-ico {
  background: none!important;
  border: none;
  padding: 0!important;
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.add-row-btn {
  grid-area: add-row-btn;
  margin: auto;
  width: 80%;
  font-size: 1.6rem;
}

.rem-row-btn {
  margin-top: 5rem;
}

.errorText {
  --text-opacity: 1 !important;
  color: rgba(197,48,48,var(--text-opacity)) !important;
  font-family: Saira,sans-serif;
  font-size: 2rem;
  font-weight: 400;
  height: 10rem;
}

.summary-success {
  padding-left: 20px;
}

.summary-header {
	align-items: center;
	display: flex;
	gap: 15px;
}

.h3-icon {
	height: 32px;
	width: 32px;
}

.summary-sent {
  color: green;
}